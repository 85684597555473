/* * * * * FLEX * * * * */
.f {
display: flex;
}

.fg {
flex: 1 100%;
}

.fdc {
flex-direction: column;
}

.fdr {
flex-direction: row;
}

.jc-fs {
justify-content: flex-start;
}

.jc-fe {
justify-content: flex-end;
}

.jc-sb {
justify-content: space-between;
}

.jc-c {
justify-content: center;
}

.as-fs {
align-self: flex-start;
}

.as-fe {
align-self: flex-end;
}

.as-c {
align-self: center;
}

.as-b {
align-self: baseline;
}

.as-s {
align-self: stretch;
}

.flex-start {
align-self: flex-start;
}

.flex-end {
align-self: flex-end;
}

.center {
align-self: center;
}

.baseline {
align-self: baseline;
}

.stretch {
align-self: stretch;
}

.ai-c {
align-items: center;
}

/* * * * * Margin * * * * */

.mla {
margin-left: auto;
}

.mra {
margin-right: auto;
}

.mca {
margin: 0 auto;
}

.mt-025, .my-025, .m-025 { margin-top: 0.25em; }
.mb-025, .my-025, .m-025 { margin-bottom: 0.25em; }
.ml-025, .mx-025, .m-025 { margin-left: 0.25em; }
.mr-025, .mx-025, .m-025 { margin-right: 0.25em; }

.mt-05, .my-05, .m-05 { margin-top: 0.5em; }
.mb-05, .my-05, .m-05 { margin-bottom: 0.5em; }
.ml-05, .mx-05, .m-05 { margin-left: 0.5em; }
.mr-05, .mx-05, .m-05 { margin-right: 0.5em; }

.mt-1, .my-1, .m-1 { margin-top: 1em; }
.mb-1, .my-1, .m-1 { margin-bottom: 1em; }
.ml-1, .mx-1, .m-1 { margin-left: 1em; }
.mr-1, .mx-1, .m-1 { margin-right: 1em; }

.mr-2 {
margin-right: 2em;
}

/* * * * * Padding * * * * */
.pt-025, .py-025, .p-025 { padding-top: 0.25em; }
.pb-025, .py-025, .p-025 { padding-bottom: 0.25em; }
.pl-025, .px-025, .p-025 { padding-left: 0.25em; }
.pr-025, .px-025, .p-025 { padding-right: 0.25em; }

.pt-05, .py-05, .p-05 { padding-top: 0.5em; }
.pb-05, .py-05, .p-05 { padding-bottom: 0.5em; }
.pl-05, .px-05, .p-05 { padding-left: 0.5em; }
.pr-05, .px-05, .p-05 { padding-right: 0.5em; }

.pt-1, .py-1, .p-1 { padding-top: 1em; }
.pb-1, .py-1, .p-1 { padding-bottom: 1em; }
.pl-1, .px-1, .p-1 { padding-left: 1em; }
.pr-1, .px-1, .p-1 { padding-right: 1em; }
  
/* * * * * Width * * * * */

.w10p {
    width: 10%;
}

.w25p {
    width: 25%;
}

.w50p {
width: 50%;
}

.w75p {
width: 75%;
}

.w100p {
width: 100%;
}

.w33p {
width: 33%;
}

/* * * * * FONT * * * * * */
.fs-1 {
    font-size: 1em;
}

input, select {
    font-size: 1em;
    padding: 3px;
}

button {
    font-size: 1em;
}

main {
    background-color: #ffffff;
    height: 100vh;
}

.ad-account-list {
    list-style-type: none;
    align-items: flex-start;
    margin: 0.5em;
    padding: 0;
}
.ad-account-list > li {
    display: block;
    margin: 0.25em;
    padding: 5px;
    border: 1px solid black;
}

/* * * * *  BUTTON * * * * */
button:focus {outline:0;}

.btn {
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-radius: 5px;
  border-color: #3f88f5;
  background-color: #ffffff;
  color: #3f88f5;
  white-space: nowrap;
}

.btn-primary {
  border-color: #3f88f5;
  background-color: #ffffff;
  color: #3f88f5;
}

.btn-primary:hover {
  background-color: #3f88f5;
}

.btn-danger {
  border-color: #f53f3f;
  background-color: #ffffff;
  color: #f53f3f;
}

.btn-danger:hover {
  background-color: #f53f3f;
}

.btn:hover {
  color: #ffffff;
  cursor: pointer;
}

.btn-sm {
  font-weight: 400;
  font-size: .75em;
  padding: 5px;
}

.btn-md {
  font-weight: 400;
  font-size: 1em;
  padding: 5px;
  min-width: 3em;
}

.btn-lg {
  font-weight: 400;
  font-size: 1.25em;
  padding: 5px;
}

.btn-neutral {
  color: #aaaaaa;
  border-color: #aaaaaa;
}

.btn-neutral:hover {
  background-color: #aaaaaa;
}
/* 
html {
    background-color: #f6f6f6;
} */

/* hr {
    border: 0.25em solid #f6f6f6;
} */