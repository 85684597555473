@import './styles/main.css';

.App {
  text-align: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mca {
  margin: 0 auto
}

/* * * * Tables * * * */

table {
  border-collapse: collapse;
}

thead {
  background-color: #d1d1d1;
}

td, th {
  text-align: left;
  padding: 10px 15px;
}

tr:nth-child(2n+2) {
  background-color: #efefef;
}

.details-table td:first-child {
  font-weight: bold;
  text-align: right;
}

/* * * * End Tables * * * */

button {
  cursor: pointer;
}

pre {
  padding: 1em;
  background-color: #f6f6f6;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.nav-item {
  margin: 0 0.5em;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff;
  transition: background-color 500ms;
}

.nav-item:hover {
  background-color: #e9e9e9;
}

a {
  text-decoration: none;
  color: #000000
}

.icon {
  cursor: pointer;
}